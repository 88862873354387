import { Carousel, Modal } from "react-bootstrap";
import * as React from "react";

class Lightbox extends React.Component {
    constructor(props) {
        super(props);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleShow(e, selectedIndex = 0) {
        // this.setState({ show: true })
        this.props.handleThumbnailSelect(e);
    }
    
    handleClose() {
        // this.setState({ show: false })
        this.props.handleLightboxClose();
    }

    handleSelect(selectedIndex, e) {
        this.props.handleLightboxControl(selectedIndex);
    }

    render() {
        return(
            <>
                <Modal className="lightbox-modal" show={this.props.show} onHide={this.handleClose}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <Carousel
                            className="lightbox-carousel"
                            interval={null}
                            activeIndex={this.props.activeIndex}
                            onSelect={this.handleSelect}
                        >
                            {this.props.lightboxSrc.map((image, index) => (
                                <Carousel.Item 
                                    key={index} 
                                    className={(image.caption !== null) ? 'with-caption' : ''}
                                >
                                    <img 
                                        src={image.src}
                                        alt={image.alt}
                                        className={`${(image.width > image.height) ? 'img-landscape' : 'img-portrait'}`}
                                    />
                                    <Carousel.Caption>
                                        {image.caption !== null
                                            ?
                                            <p>{image.caption}</p>
                                            :
                                            <p style={{textAlign: 'center'}}><em>(no caption)</em></p>
                                        }
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default Lightbox;
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import sanitizeHtml from "sanitize-html";


const DeepDive = (props) => {
    const isHidden = props.show;

    const fileItems = useStaticQuery(graphql `
        query {
            files: allFileFile {
                edges {
                    node {
                        drupal_id
                        localFile {
                            childImageSharp {
                                original {
                                    height
                                    src
                                    width
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const processDescription = (description) => {
        // console.log(description);
        description = sanitizeHtml(description, {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
            allowedAttributes: Object.assign({
                '*': ['data-*', 'loading', 'alt', 'class']
            }, sanitizeHtml.defaults.allowedAttributes),
        });

        return ReactHtmlParser(description, {
            transform: (node) => {
                if (node.type === 'tag' && node.attribs['data-entity-type'] === 'file') {
                    let uuid = node.attribs['data-entity-uuid']

                    fileItems.files.edges.forEach(file => {
                        if (file.node.drupal_id === uuid) {
                            node.attribs['src'] = file.node.localFile.childImageSharp.original.src;
                            let imgWidth = file.node.localFile.childImageSharp.original.width;
                            let imgHeight = file.node.localFile.childImageSharp.original.height;
                            let imgClass = 'img-portrait';
                            if (imgWidth > imgHeight) imgClass = 'img-landscape';
                            node.attribs['class'] = imgClass;
                        }
                    })
                }
            },
        })
    }

    return (
        <>
            {isHidden &&
                <div className={props.className}>{processDescription(props.html)}</div>
            }
        </>
    )
};

export default DeepDive;